import React from 'react';
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";

const ProjectCard = ({project, setIsModal, setProject}) => {
  const {t} = useTranslation();
  const {language} = React.useContext(I18nextContext);

  return (
    <div className="project-ctn" >
      <div className="left-ctn">
        <h3>{project.name}</h3>
        <h4>{t`previous-editions.authors-title`}</h4>
        {project.authors}
        <h4>{t`previous-editions.editions-title`}</h4>
        <p>{t(project.position)} <span className="bullet">•</span> {t(project.edition)}</p>
        <h4>{t`previous-editions.mentors-title`}</h4>
        {project.mentors}
        <h4>{t`previous-editions.tags-title`}</h4>
        <p className="project-card-tags_ctn">
          {project.tags.map(tag =>
            <>
              <span key={tag.name}>{tag.name}</span>
              <span className="bullet"> • </span>
            </>)}
        </p>
      </div>
      <button className="right-ctn img-button" onClick={() => {
        setIsModal(true);
        setProject(project);
      }}>
        {
          (language !== 'pl' && project.img_en !== undefined) ? project.img_en : project.img
        }
        <div className="img-button-readmore">
          <p>{t`previous-editions.read-more`}</p>
        </div>
      </button>
    </div>
  )
}
export default ProjectCard;
