import React, { useState, useContext } from 'react';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_MBIP.jpg';
import Layout from '../../components/layout/layout';
import './style.css';
import { StaticImage } from 'gatsby-plugin-image';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import PROJECTS_2024 from './components/projects-2024';
import PROJECTS_2023 from './components/projects-2023';
import PROJECTS_2022 from './components/projects-2022';
import PROJECTS_2021 from './components/projects-2021';
import PROJECTS_2020 from './components/projects-2020';
import SmallProjectCard from './components/small-project-card';
import ProjectCard from './components/project-card';
import ProjectModal from './components/project-modal';
import FinalSection from './components/final-section';

const PreviousEditionsPage = () => {
  const [all2024, setAll2024] = useState(false);
  const [all2023, setAll2023] = useState(false);
  const [all2022, setAll2022] = useState(false);
  const [all2021, setAll2021] = useState(false);
  const [all2020, setAll2020] = useState(false);
  const [isModal, setModal] = useState(false);
  const [currentProject, setProject] = useState(PROJECTS_2023[0]);
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  return (
    <Layout className="previous-edition_ctn" withHeader={false}>
      <Seo
        title={t`previous-editions.page-title`}
        description={t`previous-editions.page-description`}
        ogImage={ogImage}
      />

      <ProjectModal
        isModal={isModal}
        setIsModal={setModal}
        project={currentProject}
      />

      <div
        style={{
          position: 'absolute',
          right: 0,
          top: '35vh',
          width: '12vw',
          zIndex: 0
        }}>
        <StaticImage
          src="../../images/mentoring/theme-previous-edition.png"
          alt="Theme"
          placeholder="none"
          loading="lazy"
        />
      </div>

      <div className="container" style={{ position: 'relative', zIndex: 1 }}>
        <h1>{t`previous-editions.title`}</h1>
        <div
          className="project-ctn project-main-ctn"
          style={{ marginBottom: '2rem' }}>
          <div className="left-ctn">
            <h3>{PROJECTS_2024[0].name}</h3>
            <h4>{t`previous-editions.authors-title`}</h4>
            {PROJECTS_2024[0].authors}
            <h4>{t`previous-editions.editions-title`}</h4>
            <p>
              {t(PROJECTS_2024[0].position)} <span className="bullet">•</span>{' '}
              {t(PROJECTS_2024[0].edition)}
            </p>
            <h4>{t`previous-editions.mentors-title`}</h4>
            {PROJECTS_2024[0].mentors}
            <h4>{t`previous-editions.tags-title`}</h4>
            <p className="project-card-tags_ctn">
              {PROJECTS_2024[0].tags.map((tag) => (
                <>
                  <span key={tag.name}>{tag.name}</span>
                  <span className="bullet"> • </span>
                </>
              ))}
            </p>
          </div>
          <button
            className="right-ctn img-button"
            onClick={() => {
              setModal(true);
              setProject(PROJECTS_2024[0]);
            }}>
            {language !== 'pl' && PROJECTS_2024[0].img_en !== undefined
              ? PROJECTS_2024[0].img_en
              : PROJECTS_2024[0].img}
            <div className="img-button-readmore">
              <p>{t`previous-editions.read-more`}</p>
            </div>
          </button>
        </div>

        <div className="project-grid-ctn">
          <ProjectCard
            project={PROJECTS_2024[1]}
            setProject={setProject}
            setIsModal={setModal}
          />
          <ProjectCard
            project={PROJECTS_2024[2]}
            setProject={setProject}
            setIsModal={setModal}
          />
          <ProjectCard
            project={PROJECTS_2024[3]}
            setProject={setProject}
            setIsModal={setModal}
          />
          {all2024 &&
            PROJECTS_2024.slice(4).map((project) => (
              <ProjectCard
                project={project}
                setProject={setProject}
                setIsModal={setModal}
              />
            ))}
        </div>

        <div className="btn-ctn">
          <button onClick={() => setAll2024(!all2024)}>
            {!all2024
              ? t('previous-editions.show-more')
              : t('previous-editions.show-less')}
          </button>
        </div>
      </div>

      <div className="container">
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: '420vh',
            width: '7vw',
            zIndex: 0
          }}>
          <StaticImage
            src="../../images/mentoring/circles5.png"
            alt="Theme"
            placeholder="none"
            loading="lazy"
          />
        </div>
        <h2 style={{ marginTop: '8rem' }}>
          {t`previous-editions.2023-projects`}
        </h2>
        <div className="ctn-2020">
          <div className="left-ctn">
            <div className="project-ctn">
              <div className="left-ctn">
                <h3>{PROJECTS_2023[0].name}</h3>
                <h4>{t`previous-editions.authors-title`}</h4>
                {PROJECTS_2023[0].authors}
                <h4>{t`previous-editions.editions-title`}</h4>
                <p>
                  {t(PROJECTS_2023[0].position)}
                  <span className="bullet">•</span>{' '}
                  {t(PROJECTS_2023[0].edition)}
                </p>
                <h4>{t`previous-editions.mentors-title`}</h4>
                {PROJECTS_2023[0].mentors}
                <h4>{t`previous-editions.tags-title`}</h4>
                <p className="project-card-tags_ctn">
                  {PROJECTS_2023[0].tags.map((tag) => (
                    <>
                      <span key={tag.name}>{tag.name}</span>
                      <span className="bullet"> • </span>
                    </>
                  ))}
                </p>
              </div>
              <button
                className="right-ctn img-button"
                onClick={() => {
                  setModal(true);
                  setProject(PROJECTS_2023[0]);
                }}>
                {language !== 'pl' && PROJECTS_2023[0].img_en !== undefined
                  ? PROJECTS_2023[0].img_en
                  : PROJECTS_2023[0].img}
                <div className="img-button-readmore">
                  <p>{t`previous-editions.read-more`}</p>
                </div>
              </button>
            </div>
          </div>
          <div className="right">
            <SmallProjectCard
              project={PROJECTS_2023[1]}
              setProject={setProject}
              setIsModal={setModal}
            />
            <SmallProjectCard
              project={PROJECTS_2023[2]}
              setProject={setProject}
              setIsModal={setModal}
            />
            <SmallProjectCard
              project={PROJECTS_2023[3]}
              setProject={setProject}
              setIsModal={setModal}
            />
          </div>
        </div>

        <div className="project-grid-ctn" style={{ marginTop: '2rem' }}>
          {all2023 &&
            PROJECTS_2023.slice(4).map((project) => (
              <SmallProjectCard
                project={project}
                setProject={setProject}
                setIsModal={setModal}
              />
            ))}
        </div>

        <div className="btn-ctn">
          <button onClick={() => setAll2023(!all2023)}>
            {!all2023
              ? t(`previous-editions.show-more`)
              : t(`previous-editions.show-less`)}
          </button>
        </div>
      </div>

      <div className="container">
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: '420vh',
            width: '7vw',
            zIndex: 0
          }}>
          <StaticImage
            src="../../images/mentoring/circles5.png"
            alt="Theme"
            placeholder="none"
            loading="lazy"
          />
        </div>
        <h2 style={{ marginTop: '8rem' }}>
          {t`previous-editions.2022-projects`}
        </h2>
        <div className="ctn-2020">
          <div className="left-ctn">
            <div className="project-ctn">
              <div className="left-ctn">
                <h3>{PROJECTS_2022[0].name}</h3>
                <h4>{t`previous-editions.authors-title`}</h4>
                {PROJECTS_2022[0].authors}
                <h4>{t`previous-editions.editions-title`}</h4>
                <p>
                  {t(PROJECTS_2022[0].position)}
                  <span className="bullet">•</span>{' '}
                  {t(PROJECTS_2022[0].edition)}
                </p>
                <h4>{t`previous-editions.mentors-title`}</h4>
                {PROJECTS_2022[0].mentors}
                <h4>{t`previous-editions.tags-title`}</h4>
                <p className="project-card-tags_ctn">
                  {PROJECTS_2022[0].tags.map((tag) => (
                    <>
                      <span key={tag.name}>{tag.name}</span>
                      <span className="bullet"> • </span>
                    </>
                  ))}
                </p>
              </div>
              <button
                className="right-ctn img-button"
                onClick={() => {
                  setModal(true);
                  setProject(PROJECTS_2022[0]);
                }}>
                {PROJECTS_2022[0].img}
                <div className="img-button-readmore">
                  <p>{t`previous-editions.read-more`}</p>
                </div>
              </button>
            </div>
          </div>
          <div className="right">
            <SmallProjectCard
              project={PROJECTS_2022[1]}
              setProject={setProject}
              setIsModal={setModal}
            />
            <SmallProjectCard
              project={PROJECTS_2022[2]}
              setProject={setProject}
              setIsModal={setModal}
            />
            <SmallProjectCard
              project={PROJECTS_2022[3]}
              setProject={setProject}
              setIsModal={setModal}
            />
          </div>
        </div>

        <div className="project-grid-ctn" style={{ marginTop: '2rem' }}>
          {all2022 &&
            PROJECTS_2022.slice(4).map((project) => (
              <SmallProjectCard
                project={project}
                setProject={setProject}
                setIsModal={setModal}
              />
            ))}
        </div>

        <div className="btn-ctn">
          <button onClick={() => setAll2022(!all2022)}>
            {!all2022
              ? t(`previous-editions.show-more`)
              : t(`previous-editions.show-less`)}
          </button>
        </div>
      </div>

      <div className="container">
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: '420vh',
            width: '7vw',
            zIndex: 0
          }}>
          <StaticImage
            src="../../images/mentoring/circles5.png"
            alt="Theme"
            placeholder="none"
            loading="lazy"
          />
        </div>
        <h2 style={{ marginTop: '8rem' }}>
          {t`previous-editions.2021-projects`}
        </h2>
        <div className="ctn-2020">
          <div className="left-ctn">
            <div className="project-ctn">
              <div className="left-ctn">
                <h3>{PROJECTS_2021[0].name}</h3>
                <h4>{t`previous-editions.authors-title`}</h4>
                {PROJECTS_2021[0].authors}
                <h4>{t`previous-editions.editions-title`}</h4>
                <p>
                  {t(PROJECTS_2021[0].position)}
                  <span className="bullet">•</span>{' '}
                  {t(PROJECTS_2021[0].edition)}
                </p>
                <h4>{t`previous-editions.mentors-title`}</h4>
                {PROJECTS_2021[0].mentors}
                <h4>{t`previous-editions.tags-title`}</h4>
                <p className="project-card-tags_ctn">
                  {PROJECTS_2021[0].tags.map((tag) => (
                    <>
                      <span key={tag.name}>{tag.name}</span>
                      <span className="bullet"> • </span>
                    </>
                  ))}
                </p>
              </div>
              <button
                className="right-ctn img-button"
                onClick={() => {
                  setModal(true);
                  setProject(PROJECTS_2021[0]);
                }}>
                {PROJECTS_2021[0].img}
                <div className="img-button-readmore">
                  <p>{t`previous-editions.read-more`}</p>
                </div>
              </button>
            </div>
          </div>
          <div className="right">
            <SmallProjectCard
              project={PROJECTS_2021[1]}
              setProject={setProject}
              setIsModal={setModal}
            />
            <SmallProjectCard
              project={PROJECTS_2021[2]}
              setProject={setProject}
              setIsModal={setModal}
            />
            <SmallProjectCard
              project={PROJECTS_2021[3]}
              setProject={setProject}
              setIsModal={setModal}
            />
          </div>
        </div>

        <div className="project-grid-ctn" style={{ marginTop: '2rem' }}>
          {all2021 &&
            PROJECTS_2021.slice(4).map((project) => (
              <SmallProjectCard
                project={project}
                setProject={setProject}
                setIsModal={setModal}
              />
            ))}
        </div>

        <div className="btn-ctn">
          <button onClick={() => setAll2021(!all2021)}>
            {!all2021
              ? t(`previous-editions.show-more`)
              : t(`previous-editions.show-less`)}
          </button>
        </div>
      </div>

      <div className="container">
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: '220vh',
            width: '5vw',
            zIndex: 0
          }}>
          <StaticImage
            src="../../images/mentoring/theme-previous-edition-2.png"
            alt="Theme"
            placeholder="none"
            loading="lazy"
          />
        </div>
        <h2 style={{ marginTop: '8rem' }}>
          {t`previous-editions.2020-projects`}
        </h2>
        <div className="ctn-2020">
          <div className="left-ctn">
            <div className="project-ctn">
              <div className="left-ctn">
                <h3>{PROJECTS_2020[0].name}</h3>
                <h4>{t`previous-editions.authors-title`}</h4>
                {PROJECTS_2020[0].authors}
                <h4>{t`previous-editions.editions-title`}</h4>
                <p>
                  {t(PROJECTS_2020[0].position)}
                  <span className="bullet">•</span>{' '}
                  {t(PROJECTS_2020[0].edition)}
                </p>
                <h4>{t`previous-editions.mentors-title`}</h4>
                {PROJECTS_2020[0].mentors}
                <h4>{t`previous-editions.tags-title`}</h4>
                <p className="project-card-tags_ctn">
                  {PROJECTS_2020[0].tags.map((tag) => (
                    <>
                      <span key={tag.name}>{tag.name}</span>
                      <span className="bullet"> • </span>
                    </>
                  ))}
                </p>
              </div>
              <button
                className="right-ctn img-button"
                onClick={() => {
                  setModal(true);
                  setProject(PROJECTS_2020[0]);
                }}>
                {PROJECTS_2020[0].img}
                <div className="img-button-readmore">
                  <p>{t`previous-editions.read-more`}</p>
                </div>
              </button>
            </div>
          </div>
          <div className="right">
            <SmallProjectCard
              project={PROJECTS_2020[1]}
              setProject={setProject}
              setIsModal={setModal}
            />
            <SmallProjectCard
              project={PROJECTS_2020[2]}
              setProject={setProject}
              setIsModal={setModal}
            />
            <SmallProjectCard
              project={PROJECTS_2020[3]}
              setProject={setProject}
              setIsModal={setModal}
            />
          </div>
        </div>

        <div className="project-grid-ctn" style={{ marginTop: '2rem' }}>
          {all2020 &&
            PROJECTS_2020.slice(4).map((project) => (
              <SmallProjectCard
                project={project}
                setProject={setProject}
                setIsModal={setModal}
              />
            ))}
        </div>

        <div className="btn-ctn">
          <button onClick={() => setAll2020(!all2020)}>
            {!all2020
              ? t(`previous-editions.show-more`)
              : t(`previous-editions.show-less`)}
          </button>
        </div>
      </div>

      <FinalSection />
    </Layout>
  );
};

export default PreviousEditionsPage;
