import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const FinalSection = () => {

  const {t} = useTranslation();

  return (
    <div style={{position: 'relative', marginTop: '6rem'}}>
      <div className="right-bg-ctn"/>
      <div className="container">
        <div className="follow-up-ctn">
          <div className="left-ctn">
            <h4>
              {t`previous-editions.getting-interested`}
            </h4>
            <p>{t`previous-editions.getting-interested-text`}</p>
            <div className="btn-ctn2">
              <Link to="/contact">
                {t`previous-editions.write-to-us`}
              </Link>
            </div>
            <div className="btn-ctn2 btn-ctn_no-bg2" style={{marginTop: '0.5rem'}}>
              <Link to="/under-construction">
                {t`previous-editions.read-more-2`}
                <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: '1rem'}}/>
              </Link>
            </div>
          </div>
          <StaticImage
            className="dude"
            src="../../../images/mentoring/photo-dude.png"
            alt="Dude"
            placeholder="none"
            loading="lazy"
          />
          <div className="right-ctn">
            <h4>
              {t`previous-editions.register`}
            </h4>
            <p>{t`previous-editions.register-text`}</p>
            <div className="btn-ctn2">
              <Link to="/registration">
                {t`previous-editions.join-us`}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinalSection;


