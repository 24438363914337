import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  APP_TAG,
  ARTIFICIAL_INTELLIGENCE_TAG, DIET_TAG, HOSPITALS_TAG,
  IT_TAG,
  MED_INNOVATIONS_TAG,
  PATHOLOGY_TAG,
  PRINTING_3D_TAG,
  TECH_TAG, TEST_TAG, WELL_BEING_TAG
} from './tags';

const PROJECTS_2020 =  [
  {
    name: 'AI PQC',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/aipqc.jpg"
        alt="Projekt: AI PQC"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Karolina Pluta <span className="bullet">•</span> Bogna Stolc <span className="bullet">•</span> Bartek Malinowski <span className="bullet">•</span> Jędrzej Czapla</p>
      </>
    ),
    edition: `previous-editions.project-2020-edition`,
    position: 'previous-editions.project-2020-1-position',
    mentors: (
      <p>Artur Chajewski <span className="bullet">•</span> Perlan Technologies</p>
    ),
    tags: [IT_TAG, ARTIFICIAL_INTELLIGENCE_TAG, PATHOLOGY_TAG],
    description: 'previous-editions.project-2020-1-description',
    trl_level: 'previous-editions.project-2020-1-trl_level',
    advantages_1: 'previous-editions.project-2020-1-advantages_1',
    advantages_2: 'previous-editions.project-2020-1-advantages_2',
    applications_1: 'previous-editions.project-2020-1-applications_1',
    applications_2: 'previous-editions.project-2020-1-applications_2',
    applications_3: 'previous-editions.project-2020-1-applications_3',
  },
  {
    name: 'Kuźnia 3D',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Kuznia.jpg"
        alt="Projekt: Kuźnia 3D"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Maciek Łuczak</p>
        <p>Eryk Rutkowski</p>
        <p>Piotr Łuczak</p>
        <p>Mateusz Sobieraj</p>
      </>
    ),
    edition: `previous-editions.project-2020-edition`,
    position: 'previous-editions.project-2020-2-position',
    mentors: (
      <p>Sebastian Zaremba<span className="bullet">•</span> Blodon</p>
    ),
    tags: [PRINTING_3D_TAG, MED_INNOVATIONS_TAG, TECH_TAG],
    description: 'previous-editions.project-2020-2-description',
    trl_level: 'previous-editions.project-2020-2-trl_level',
    advantages_1: 'previous-editions.project-2020-2-advantages_1',
    advantages_2: 'previous-editions.project-2020-2-advantages_2',
    applications_1: 'previous-editions.project-2020-2-applications_1',
    applications_2: 'previous-editions.project-2020-2-applications_2',
    applications_3: 'previous-editions.project-2020-2-applications_3',
  },
  {
    name: 'CRP Sense',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/CRPSense.jpg"
        alt="Projekt: CRP Sense"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Sonia Demianiuk</p>
        <p>Anna Lasoń</p>
        <p>Krzysztof Okuniewski</p>
        <p>Marcin Wiktorowski</p>
      </>
    ),
    edition: `previous-editions.project-2020-edition`,
    position: 'previous-editions.project-2020-3-position',
    mentors: (
      <p>Ireneusz Martyniuk <span className="bullet">•</span> Invento Capital</p>
    ),
    tags: [TEST_TAG, MED_INNOVATIONS_TAG, TECH_TAG],
    description: 'previous-editions.project-2020-3-description',
    trl_level: 'previous-editions.project-2020-3-trl_level',
    advantages_1: 'previous-editions.project-2020-3-advantages_1',
    advantages_2: 'previous-editions.project-2020-3-advantages_2',
    applications_1: 'previous-editions.project-2020-3-applications_1',
    applications_2: 'previous-editions.project-2020-3-applications_2',
    applications_3: 'previous-editions.project-2020-3-applications_3',
  },
  {
    name: 'Med Direct',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/MedDirect.jpg"
        alt="Projekt: Med Direct"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Ulyana Samusenkava</p>
        <p>Yulita Kroitar</p>
      </>
    ),
    edition: `previous-editions.project-2020-edition`,
    position: 'previous-editions.project-2020-4-position',
    mentors: (
      <p>Grzegorz Piestrak <span className="bullet">•</span> M2M Team</p>
    ),
    tags: [IT_TAG, APP_TAG, HOSPITALS_TAG],
    description: 'previous-editions.project-2020-4-description',
    trl_level: 'previous-editions.project-2020-4-trl_level',
    advantages_1: 'previous-editions.project-2020-4-advantages_1',
    advantages_2: 'previous-editions.project-2020-4-advantages_2',
    applications_1: 'previous-editions.project-2020-4-applications_1',
    applications_2: 'previous-editions.project-2020-4-applications_2',
    applications_3: 'previous-editions.project-2020-4-applications_3',
  },
  {
    name: 'InterBio',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/InterBio.jpg"
        alt="Projekt: InterBio"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Marta Spirydowicz</p>
        <p>Katarzyna Świerkula</p>
        <p>Jagoda Starek</p>
        <p>Barbara Pilecka</p>
      </>
    ),
    edition: `previous-editions.project-2020-edition`,
    position: 'previous-editions.project-2020-5-position',
    mentors: (
      <p>Michał Pękała <span className="bullet">•</span> Znany Lekarz</p>
    ),
    tags: [IT_TAG, APP_TAG, HOSPITALS_TAG],
    description: 'previous-editions.project-2020-5-description',
    trl_level: 'previous-editions.project-2020-5-trl_level',
    advantages_1: 'previous-editions.project-2020-5-advantages_1',
    advantages_2: 'previous-editions.project-2020-5-advantages_2',
    applications_1: 'previous-editions.project-2020-5-applications_1',
    applications_2: 'previous-editions.project-2020-5-applications_2',
    applications_3: 'previous-editions.project-2020-5-applications_3',
  },
  {
    name: 'Nernst',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Nernst.jpg"
        alt="Projekt: Nernst"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Karolina Olech</p>
        <p>Aleksandra Wach</p>
        <p>Piotr Bieńkowski</p>
        <p>Jakub Piznal</p>
      </>
    ),
    edition: `previous-editions.project-2020-edition`,
    position: 'previous-editions.project-2020-6-position',
    mentors: (
      <p>Arkadiusz Szterk <span className="bullet">•</span> Narodowy Instytut Leków</p>
    ),
    tags: [IT_TAG, APP_TAG, DIET_TAG],
    description: 'previous-editions.project-2020-6-description',
    trl_level: 'previous-editions.project-2020-6-trl_level',
    advantages_1: 'previous-editions.project-2020-6-advantages_1',
    advantages_2: 'previous-editions.project-2020-6-advantages_2',
    applications_1: 'previous-editions.project-2020-6-applications_1',
    applications_2: 'previous-editions.project-2020-6-applications_2',
    applications_3: 'previous-editions.project-2020-6-applications_3',
  },
  {
    name: 'Profilactica',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_Profilactica.jpg"
        alt="Projekt: Profilactica"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Dominika Osuch</p>
      </>
    ),
    edition: `previous-editions.project-2020-edition`,
    position: 'previous-editions.project-2020-7-position',
    mentors: (
      <p>Janusz Kowalski <span className="bullet">•</span> MEDmeetsTECH</p>
    ),
    tags: [IT_TAG, APP_TAG, WELL_BEING_TAG],
    description: 'previous-editions.project-2020-7-description',
    trl_level: 'previous-editions.project-2020-7-trl_level',
    advantages_1: 'previous-editions.project-2020-7-advantages_1',
    advantages_2: 'previous-editions.project-2020-7-advantages_2',
    applications_1: 'previous-editions.project-2020-7-applications_1',
    applications_2: 'previous-editions.project-2020-7-applications_2',
    applications_3: 'previous-editions.project-2020-7-applications_3',
  },
]

export default PROJECTS_2020;

